/* Font Family */
/* Borda arredondada */
body > #root > .about > main {
  color: #003063;
}
body > #root > .about > main h2 {
  font-size: 34px;
  font-weight: 900;
}
body > #root > .about > main h3 {
  font-size: 30px;
  font-weight: 900;
}
body > #root > .about > main > div {
  padding: 112px 0;
}
body > #root > .about > main > .content,
body > #root > .about > main > div > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .content,
  body > #root > .about > main > div > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .purpose {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  background-color: #F3F7FA;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .purpose {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .purpose > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .purpose > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .purpose > .content > .title {
  width: 500px;
  font-size: 46px;
  font-weight: 900;
}
body > #root > .about > main > .purpose > .content > .text {
  width: 550px;
  font-size: 16px;
  line-height: 25px;
}
body > #root > .about > main > .dna {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .dna {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .dna > .texts {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 45%;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .dna > .texts > p {
  font-size: 16px;
  line-height: 25px;
}
body > #root > .about > main > .dna > .texts > ul {
  padding: 0;
  list-style: none;
}
body > #root > .about > main > .dna > .texts > ul > li {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 5px 0;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts > ul > li {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .dna > .texts > ul > li > img {
  margin-right: 5px;
}
body > #root > .about > main > .dna > .image {
  width: 45%;
  margin-left: 10%;
}
body > #root > .about > main > .dna > .image > img {
  width: 100%;
}
body > #root > .about > main > .team {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background: url('../../assets/images/about/bkg.jpg') #FFF;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .team {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .team > .content > h2,
body > #root > .about > main > .team > .content > h3,
body > #root > .about > main > .team > .content > h4 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > h2,
  body > #root > .about > main > .team > .content > h3,
  body > #root > .about > main > .team > .content > h4 {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .team > .content > h3 {
  margin-top: 80px;
}
body > #root > .about > main > .team > .content > h4 {
  font-size: 16px;
  font-weight: 400;
}
body > #root > .about > main > .team > .content > .partners,
body > #root > .about > main > .team > .content > .advisors {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .partners,
  body > #root > .about > main > .team > .content > .advisors {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .team > .content > .partners > div,
body > #root > .about > main > .team > .content > .advisors > div {
  width: 45%;
}
body > #root > .about > main > .team > .content > .partners > div > .photo > img,
body > #root > .about > main > .team > .content > .advisors > div > .photo > img {
  width: 100%;
}
body > #root > .about > main > .team > .content > .partners > div > .name,
body > #root > .about > main > .team > .content > .advisors > div > .name {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 700;
}
body > #root > .about > main > .team > .content > .partners > div > ul,
body > #root > .about > main > .team > .content > .advisors > div > ul {
  margin-bottom: 25px;
  padding-left: 15px;
}
body > #root > .about > main > .team > .content > .partners > div > ul > li,
body > #root > .about > main > .team > .content > .advisors > div > ul > li {
  margin: 10px 0;
  line-height: 20px;
}
body > #root > .about > main > .team > .content > .partners > div > .links,
body > #root > .about > main > .team > .content > .advisors > div > .links {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .partners > div > .links,
  body > #root > .about > main > .team > .content > .advisors > div > .links {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .team > .content > .partners > div > .links > a,
body > #root > .about > main > .team > .content > .advisors > div > .links > a {
  font-size: 22px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .about > main > .team > .content > .partners > div > .links > a:hover,
body > #root > .about > main > .team > .content > .advisors > div > .links > a:hover {
  color: #666;
}
body > #root > .about > main > .team > .content > .cta {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 96px;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .cta {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .team > .content > .cta > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  background-color: #235AB1;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .cta > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .about > main > .team > .content > .cta > a:hover {
  background-color: #1b4486;
}
@media screen and (max-width: 767px) {
  body > #root > .about > main h2 {
    font-size: 40px;
  }
  body > #root > .about > main h3 {
    font-size: 34px;
  }
  body > #root > .about > main > .purpose > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .about > main > .purpose > .content > .title {
    margin-bottom: 20px;
    width: 100%;
    font-size: 34px;
  }
  body > #root > .about > main > .purpose > .content > .text {
    width: 100%;
    font-size: 17px;
    line-height: 26px;
  }
  body > #root > .about > main > .dna {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .about > main > .dna > .texts {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 100%;
  }
  body > #root > .about > main > .dna > .texts > h2 {
    margin-bottom: 10px;
  }
  body > #root > .about > main > .dna > .texts > p {
    font-size: 18px;
    line-height: 26px;
  }
  body > #root > .about > main > .dna > .texts > ul > li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 20px 0;
    font-size: 16px;
  }
  body > #root > .about > main > .dna > .texts > ul > li > div {
    width: 100%;
  }
  body > #root > .about > main > .dna > .image {
    width: 100%;
    margin: 25px 0 0;
  }
  body > #root > .about > main > .dna > .image > img {
    width: 100%;
  }
  body > #root > .about > main > .team {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    background: url('../../assets/images/about/bkg.jpg') #FFF;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  body > #root > .about > main > .team > .content > h2 {
    margin-bottom: 15px;
  }
  body > #root > .about > main > .team > .content > h3 {
    margin-top: 40px;
  }
  body > #root > .about > main > .team > .content > h3:nth-child(5) {
    margin-top: 80px;
  }
  body > #root > .about > main > .team > .content > h4 {
    font-size: 20px;
  }
  body > #root > .about > main > .team > .content > .partners,
  body > #root > .about > main > .team > .content > .advisors {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .about > main > .team > .content > .partners > div,
  body > #root > .about > main > .team > .content > .advisors > div {
    margin-bottom: 60px;
    padding: 0;
    width: 100%;
  }
  body > #root > .about > main > .team > .content > .partners > div.partner2,
  body > #root > .about > main > .team > .content > .advisors > div.partner2,
  body > #root > .about > main > .team > .content > .partners > div.advisor2,
  body > #root > .about > main > .team > .content > .advisors > div.advisor2 {
    margin: 0;
  }
  body > #root > .about > main > .team > .content > .partners > div > .photo > img,
  body > #root > .about > main > .team > .content > .advisors > div > .photo > img {
    width: 100%;
  }
  body > #root > .about > main > .team > .content > .partners > div > .name,
  body > #root > .about > main > .team > .content > .advisors > div > .name {
    font-size: 20px;
  }
  body > #root > .about > main > .team > .content > .partners > div > ul,
  body > #root > .about > main > .team > .content > .advisors > div > ul {
    margin-bottom: 20px;
  }
  body > #root > .about > main > .team > .content > .partners > div > ul > li,
  body > #root > .about > main > .team > .content > .advisors > div > ul > li {
    margin: 10px 0;
    line-height: 22px;
  }
  body > #root > .about > main > .team > .content > .partners > div > .links,
  body > #root > .about > main > .team > .content > .advisors > div > .links {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 20px;
  }
  body > #root > .about > main > .team > .content > .partners > div > .links > a,
  body > #root > .about > main > .team > .content > .advisors > div > .links > a {
    font-size: 26px;
  }
  body > #root > .about > main > .team > .content > .cta {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 100px;
  }
  body > #root > .about > main > .team > .content > .cta > h3 {
    font-size: 30px;
    text-align: center;
  }
  body > #root > .about > main > .team > .content > .cta > a {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .purpose > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .dna {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts > ul > li {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .partners,
  body > #root > .about > main > .team > .content > .advisors {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .partners > div > .links,
  body > #root > .about > main > .team > .content > .advisors > div > .links {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .cta {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .about > main h2 {
    font-size: 38px;
  }
  body > #root > .about > main h3 {
    font-size: 32px;
  }
  body > #root > .about > main > .purpose > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .about > main > .purpose > .content > .title {
    margin-bottom: 20px;
    width: 70%;
    font-size: 40px;
    text-align: center;
  }
  body > #root > .about > main > .purpose > .content > .text {
    width: 70%;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
  body > #root > .about > main > .dna > .texts {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 90%;
  }
  body > #root > .about > main > .dna > .texts > h2 {
    margin-bottom: 10px;
    font-size: 34px;
  }
  body > #root > .about > main > .dna > .texts > p {
    font-size: 16px;
    line-height: 26px;
  }
  body > #root > .about > main > .dna > .texts > ul > li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 20px 0;
    font-size: 14px;
  }
  body > #root > .about > main > .dna > .texts > ul > li > div {
    width: 100%;
  }
  body > #root > .about > main > .dna > .image {
    width: 90%;
    margin-left: 4%;
  }
  body > #root > .about > main > .dna > .image > img {
    width: 100%;
  }
  body > #root > .about > main > .team > .content > h2 {
    margin-bottom: 15px;
  }
  body > #root > .about > main > .team > .content > h3 {
    margin-top: 40px;
  }
  body > #root > .about > main > .team > .content > h3:nth-child(5) {
    margin-top: 80px;
  }
  body > #root > .about > main > .team > .content > h4 {
    font-size: 20px;
  }
  body > #root > .about > main > .team > .content > .partners > div,
  body > #root > .about > main > .team > .content > .advisors > div {
    margin-bottom: 60px;
    padding: 0;
    width: 100%;
  }
  body > #root > .about > main > .team > .content > .partners > div.partner2,
  body > #root > .about > main > .team > .content > .advisors > div.partner2,
  body > #root > .about > main > .team > .content > .partners > div.advisor2,
  body > #root > .about > main > .team > .content > .advisors > div.advisor2 {
    margin-left: 30px;
  }
  body > #root > .about > main > .team > .content > .partners > div > .photo > img,
  body > #root > .about > main > .team > .content > .advisors > div > .photo > img {
    width: 100%;
  }
  body > #root > .about > main > .team > .content > .partners > div > .links,
  body > #root > .about > main > .team > .content > .advisors > div > .links {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 20px;
  }
  body > #root > .about > main > .team > .content > .partners > div > .links > a,
  body > #root > .about > main > .team > .content > .advisors > div > .links > a {
    font-size: 26px;
  }
  body > #root > .about > main > .team > .content > .cta {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 100px;
  }
  body > #root > .about > main > .team > .content > .cta > h3 {
    font-size: 30px;
    text-align: center;
  }
  body > #root > .about > main > .team > .content > .cta > a {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .about > main > .purpose > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts > ul > li {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .partners > div > .links,
  body > #root > .about > main > .team > .content > .advisors > div > .links {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .cta {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .about > main h2 {
    font-size: 36px;
  }
  body > #root > .about > main h3 {
    font-size: 30px;
  }
  body > #root > .about > main > .purpose > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .about > main > .purpose > .content > .title {
    margin-bottom: 20px;
    width: 90%;
    font-size: 36px;
    text-align: center;
  }
  body > #root > .about > main > .purpose > .content > .text {
    width: 80%;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
  body > #root > .about > main > .dna > .texts {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 90%;
  }
  body > #root > .about > main > .dna > .texts > h2 {
    margin-bottom: 10px;
    font-size: 34px;
  }
  body > #root > .about > main > .dna > .texts > p {
    font-size: 16px;
    line-height: 26px;
  }
  body > #root > .about > main > .dna > .texts > ul > li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 20px 0;
    font-size: 15px;
  }
  body > #root > .about > main > .dna > .texts > ul > li > div {
    width: 100%;
  }
  body > #root > .about > main > .dna > .image {
    width: 90%;
    margin-left: 8%;
  }
  body > #root > .about > main > .dna > .image > img {
    width: 100%;
  }
  body > #root > .about > main > .team > .content > h2 {
    margin-bottom: 15px;
    font-size: 34px;
  }
  body > #root > .about > main > .team > .content > h3 {
    margin-top: 40px;
    font-size: 30px;
  }
  body > #root > .about > main > .team > .content > h3:nth-child(5) {
    margin-top: 80px;
  }
  body > #root > .about > main > .team > .content > .partners > div,
  body > #root > .about > main > .team > .content > .advisors > div {
    margin-bottom: 60px;
    padding: 0;
    width: 100%;
  }
  body > #root > .about > main > .team > .content > .partners > div.partner2,
  body > #root > .about > main > .team > .content > .advisors > div.partner2,
  body > #root > .about > main > .team > .content > .partners > div.advisor2,
  body > #root > .about > main > .team > .content > .advisors > div.advisor2 {
    margin-left: 50px;
  }
  body > #root > .about > main > .team > .content > .partners > div > .photo > img,
  body > #root > .about > main > .team > .content > .advisors > div > .photo > img {
    width: 100%;
  }
  body > #root > .about > main > .team > .content > .partners > div > .links,
  body > #root > .about > main > .team > .content > .advisors > div > .links {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 20px;
  }
  body > #root > .about > main > .team > .content > .partners > div > .links > a,
  body > #root > .about > main > .team > .content > .advisors > div > .links > a {
    font-size: 26px;
  }
  body > #root > .about > main > .team > .content > .cta {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 100px;
  }
  body > #root > .about > main > .team > .content > .cta > h3 {
    font-size: 30px;
    text-align: center;
  }
  body > #root > .about > main > .team > .content > .cta > a {
    font-size: 12px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .about > main > .purpose > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .about > main > .dna > .texts > ul > li {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .partners > div > .links,
  body > #root > .about > main > .team > .content > .advisors > div > .links {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .about > main > .team > .content > .cta {
    flex: 0 0 auto !important;
  }
}
