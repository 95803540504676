/* Font Family */
/* Borda arredondada */
body > #root > .container > main > footer {
  width: 100%;
  color: #003063;
  background-color: #FFF;
}
body > #root > .container > main > footer .container {
  width: 1200px;
  height: inherit;
  margin-left: auto;
  margin-right: auto;
}
body > #root > .container > main > footer .container > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 40px 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > footer .container > .infos > .logo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .logo {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > footer .container > .infos > .logo > img {
  width: 80%;
}
body > #root > .container > main > footer .container > .infos > .links {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .links {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > footer .container > .infos > .links > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 600;
  color: #003063;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .links > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > footer .container > .infos > .links > a:not(:first-child) {
  margin-left: 15px;
}
body > #root > .container > main > footer .container > .infos > .links > a:hover {
  color: #666;
}
body > #root > .container > main > footer .container > .infos > .social {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .social {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > footer .container > .infos > .social > a {
  color: #003063;
  margin-right: 10px;
  font-size: 18px;
}
body > #root > .container > main > footer .container > .infos > .social > .social_icon:hover {
  color: #ffcd00;
}
body > #root > .container > main > footer .container > .copyright {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px 0;
  text-align: center;
  font-size: 12px;
  border-top: solid 0.1px #F0F0F0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .copyright {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > footer .container > .copyright > p {
  margin: 5px 0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > footer {
    padding-bottom: 70px;
  }
  body > #root > .container > main > footer .container {
    width: 100%;
  }
  body > #root > .container > main > footer .container > .infos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    padding: 60px 0;
    width: 100%;
  }
  body > #root > .container > main > footer .container > .infos > .logo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: auto;
    width: 50%;
  }
  body > #root > .container > main > footer .container > .infos > .logo > img {
    width: 100%;
  }
  body > #root > .container > main > footer .container > .infos > .links {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 15px 0 35px;
  }
  body > #root > .container > main > footer .container > .infos > .links > a {
    padding: 3px;
    font-size: 18px;
  }
  body > #root > .container > main > footer .container > .infos > .links > a:not(:first-child) {
    margin-left: 8px;
  }
  body > #root > .container > main > footer .container > .infos > .social {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 100%;
  }
  body > #root > .container > main > footer .container > .infos > .social > a {
    margin-right: 20px;
    font-size: 23px;
  }
  body > #root > .container > main > footer .container > .copyright {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    padding: 15px 0;
    text-align: center;
    font-size: 10px;
    border-top: solid 0.1px #F0F0F0;
  }
  body > #root > .container > main > footer .container > .copyright > p {
    margin: 0;
  }
  body > #root > .container > main > footer .container > .copyright > p:first-child {
    display: none;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .logo {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .links {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .social {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .copyright {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  body > #root > .container > main > footer .container {
    width: 100%;
  }
  body > #root > .container > main > footer .container > .infos {
    padding: 40px 30px;
  }
  body > #root > .container > main > footer .container > .infos > .logo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 20%;
  }
  body > #root > .container > main > footer .container > .infos > .logo > img {
    width: 90%;
  }
  body > #root > .container > main > footer .container > .infos > .links > a {
    font-size: 14px;
  }
  body > #root > .container > main > footer .container > .infos > .social > a {
    font-size: 16px;
  }
  body > #root > .container > main > footer .container > .copyright > p {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .container > main > footer .container > .infos > .logo {
    flex: 0 0 auto !important;
  }
}
