/* Font Family */
/* Borda arredondada */
body > #root > .home > main > div {
  padding: 112px 0;
}
body > #root > .home > main > .content,
body > #root > .home > main > div > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .content,
  body > #root > .home > main > div > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .carousel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .carousel {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .carousel > h2 {
  font-family: "Urbanist", "Roboto", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 33.6px;
  text-align: left;
  color: #003063;
}
body > #root > .home > main > .carousel > .slider-container {
  width: 250vh;
}
body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada {
  filter: grayscale(100%);
}
body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada:hover {
  transition: 800ms;
  filter: grayscale(0%);
  transition: transform 0.8s ease;
  transform: scale(1.2);
}
body > #root > .home > main > .freeMarket {
  background-color: #F3F7FA;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  color: #003063;
  margin-bottom: 10vh;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > p {
  font-family: "Urbanist", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: #235AB1;
}
body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > h3 {
  font-family: "Urbanist", "Roboto", "Arial", sans-serif;
  font-size: 48px;
  font-weight: 800;
  line-height: 57.6px;
  text-align: left;
}
body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > p {
  font-size: 16px;
  line-height: 25px;
  color: #003063;
}
body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul {
  margin-top: 5vh;
  padding: 0;
  list-style: none;
}
body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 5px 0;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .freeMarket > .content > .video > .rounded-iframe {
  border-radius: 15px;
  border: 0vh;
  width: 1200px;
  height: 738px;
}
body > #root > .home > main > .freeMarket > .content > .infoLink {
  margin-top: 15vh;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .infoLink {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .freeMarket > .content > .infoLink > button {
  width: 203px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 6px;
  background-color: #235AB1;
  color: #FFF;
  border: 0vh;
  margin-right: 5vh;
  font-family: "Urbanist", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 28px;
  text-align: left;
}
body > #root > .home > main > .freeMarket > .content > .infoLink > button > a {
  color: #FFF;
}
body > #root > .home > main > .freeMarket > .content > .infoLink > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .infoLink > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .freeMarket > .content > .infoLink > div > a {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-right: 2vh;
}
body > #root > .home > main > .freeMarket > .content > .infoLink > div > i {
  margin-top: 5px;
}
body > #root > .home > main > .system {
  background: #2944a4;
  background: linear-gradient(65deg, rgba(41, 68, 164, 0.95561975) 0%, #0c76d6 100%);
  color: #FFF;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .system {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .system > .content > .infoSystem {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box {
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 10vh;
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > div > .subtitulo {
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > div > p {
  font-size: 16px;
  font-weight: 200;
  line-height: 20px;
  text-align: left;
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens > div {
  margin-right: 5vh;
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens > div > h2 {
  margin-top: 5vh;
  color: #ffcd00;
  font-size: 38px;
  line-height: 10px;
  margin-bottom: 3vh;
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens > div > p {
  font-size: 16px;
  font-weight: 200;
  line-height: 20px;
  text-align: left;
}
body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .button > button {
  background-color: #ffcd00;
  width: 168px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 6px;
  color: #003063;
  font-family: "Urbanist", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  font-weight: 800;
  border: 0vh;
}
body > #root > .home > main > .questions {
  background-color: #F3F7FA;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .questions {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .questions > .content > .context {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .questions > .content > .context {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .questions > .content > .context > .commonQuestion > div {
  width: 500px;
  height: 243px;
}
body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > h2 {
  font-size: 48px;
  font-weight: 800;
  line-height: 57.6px;
  text-align: left;
  color: #003063;
  margin-top: 3vh;
}
body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #003063;
}
body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > button {
  background-color: #235AB1;
  width: 203px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  border-radius: 6px;
  color: #FFF;
  border: 0vh;
  margin-top: 2vh;
  font-family: "Urbanist", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
}
body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > button > a {
  color: white;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .carousel > h2 {
    text-align: center;
    margin-bottom: 5vh;
  }
  body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada {
    width: 150px !important;
  }
  body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada > .img {
    filter: grayscale(0%);
  }
  body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada:hover {
    transition: 800ms;
    filter: grayscale(0%);
    transition: transform 0.8s ease;
    transform: scale(1.2);
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-bottom: 25px;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: #235AB1;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 38.4px;
    text-align: left;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul {
    padding: 0;
    list-style: none;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 5px 0;
  }
  body > #root > .home > main > .freeMarket > .content > .video > .rounded-iframe {
    border-radius: 0px;
    width: 343px;
    height: 193px;
    margin-bottom: 20px;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink {
    margin-top: 0%;
    margin-right: 0%;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > button {
    width: 203px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    background-color: #235AB1;
    color: #FFF;
    border: 0vh;
    margin-right: 5vh;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 2vh;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > div > a {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-right: 2vh;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > div > i {
    margin-top: 5px;
  }
  body > #root > .home > main > .system {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    flex-direction: column-reverse !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .divImg > .img {
    width: 343px;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box {
    margin-top: 0vh;
    margin-bottom: 5vh;
    margin-left: 0vh;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > div > .subtitulo {
    font-size: 30px;
    font-weight: 800;
    line-height: 30px;
    text-align: left;
    margin-bottom: 3vh;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > div > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;
    text-align: left;
    opacity: 0.8px;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens > div > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 36px;
    font-weight: 800;
    line-height: 0px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens > div > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .button > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .button > button {
    background-color: #ffcd00;
    width: 168px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    color: #003063;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: 800;
    border: 0vh;
  }
  body > #root > .home > main > .questions {
    background-color: #F3F7FA;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .questions > .content > .context {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion {
    margin-bottom: 5vh;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div {
    width: 343px;
    height: 243px;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 38.4px;
    text-align: left;
    color: #003063;
    margin-top: 3vh;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #003063;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > button {
    background-color: #235AB1;
    width: 203px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 6px;
    color: white;
    border: 0vh;
    margin-top: 2vh;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .infoLink {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .infoLink > div {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .questions {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .home > main > .questions > .content > .context {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .home > main > .carousel {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  body > #root > .home > main > .carousel > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 40px;
    font-weight: 800;
    line-height: 33.6px;
    text-align: center;
    color: #003063;
  }
  body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada {
    filter: grayscale(100%);
  }
  body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada:hover {
    transition: 800ms;
    filter: grayscale(0%);
    transition: transform 0.8s ease;
    transform: scale(1.2);
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
    margin-bottom: 20px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: #235AB1;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > h3 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 38.4px;
    text-align: left;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket {
    margin-top: 0vh;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul {
    margin-top: 0vh;
    padding: 0;
    list-style: none;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 5px 0;
  }
  body > #root > .home > main > .freeMarket > .content > .video > .rounded-iframe {
    border-radius: 0px;
    border: 0vh;
    width: 765px;
    height: 500px;
  }
  body > #root > .home > main > .freeMarket > .content .infoLink {
    margin-top: 20px;
  }
  body > #root > .home > main > .system {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    flex-direction: column-reverse !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .divImg > .img {
    width: 765px;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box {
    margin-top: 0vh;
    margin-bottom: 5vh;
    margin-left: 0vh;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > div > .subtitulo {
    font-size: 30px;
    font-weight: 800;
    line-height: 30px;
    text-align: left;
    margin-bottom: 3vh;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > div > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 100;
    line-height: 24px;
    text-align: left;
    opacity: 0.8px;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens > div > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 36px;
    font-weight: 800;
    line-height: 0px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens > div > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 100;
    line-height: 24px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .button > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .button > button {
    background-color: #ffcd00;
    width: 168px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    color: #003063;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: 800;
    border: 0vh;
  }
  body > #root > .home > main > .questions {
    background-color: #F3F7FA;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .questions > .content > .context {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion {
    margin-bottom: 0vh;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div {
    width: 600px;
    height: 243px;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 38.4px;
    text-align: left;
    color: #003063;
    margin-top: 3vh;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #003063;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > button {
    background-color: #235AB1;
    width: 203px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 6px;
    color: white;
    border: 0vh;
    margin-top: 2vh;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .carousel {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem > .textSystem > .box > .porcentagens {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .questions {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .home > main > .questions > .content > .context {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .home > main > .carousel {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 15vh;
    margin-bottom: 10vh;
  }
  body > #root > .home > main > .carousel > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 33.6px;
    text-align: left;
    color: #003063;
  }
  body > #root > .home > main > .carousel > .slider-container {
    width: 250vh;
  }
  body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada {
    filter: grayscale(100%);
  }
  body > #root > .home > main > .carousel > .slider-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .imagem-desaturada:hover {
    transition: 800ms;
    filter: grayscale(0%);
    transition: transform 0.8s ease;
    transform: scale(1.2);
  }
  body > #root > .home > main > .freeMarket {
    background-color: #F3F7FA;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    color: #003063;
    margin-bottom: 35px;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title {
    margin-right: 20px;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > p {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    color: #235AB1;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .title > h2 {
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 38px;
    font-weight: 800;
    line-height: 57.6px;
    text-align: left;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > p {
    font-size: 16px;
    line-height: 25px;
    color: #003063;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul {
    margin-top: 5vh;
    padding: 0;
    list-style: none;
  }
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 5px 0;
  }
  body > #root > .home > main > .freeMarket > .content > .video > .rounded-iframe {
    border-radius: 15px;
    border: 0vh;
    width: 900px;
    height: 473px;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink {
    margin-top: 5vh;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > button {
    width: 203px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    background-color: #235AB1;
    color: #FFF;
    border: 0vh;
    margin-right: 5vh;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 28px;
    text-align: left;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > button > a {
    color: #FFF;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > div > a {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-right: 2vh;
  }
  body > #root > .home > main > .freeMarket > .content > .infoLink > div > i {
    margin-top: 5px;
  }
  body > #root > .home > main > .system {
    background: #2944a4;
    background: linear-gradient(65deg, rgba(41, 68, 164, 0.95561975) 0%, #0c76d6 100%);
    color: #FFFFFF;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem > .divImg > .img {
    margin-top: 60px;
    width: 500px;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box {
    margin-top: 5vh;
    margin-bottom: 0px;
    margin-left: 2vh;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > div > .subtitulo {
    font-size: 28px;
    font-weight: 800;
    line-height: 30px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > div > p {
    font-size: 15px;
    font-weight: 100;
    line-height: 20px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens > div {
    margin-right: 5vh;
    margin: 5px;
    margin-top: 0px;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens > div > h2 {
    margin-top: 1vh;
    color: #ffcd00;
    font-size: 32px;
    line-height: 30px;
    margin-bottom: 0vh;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens > div > p {
    font-size: 16px;
    font-weight: 100;
    line-height: 20px;
    text-align: left;
  }
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .button > button {
    background-color: #ffcd00;
    width: 168px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    color: #003063;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: 800;
    border: 0vh;
  }
  body > #root > .home > main > .questions {
    background-color: #F3F7FA;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .questions > .content > .context {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div {
    width: 500px;
    height: 243px;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > h2 {
    font-size: 48px;
    font-weight: 800;
    line-height: 57.6px;
    text-align: left;
    color: #003063;
    margin-top: 3vh;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #003063;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > button {
    background-color: #235AB1;
    width: 203px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 6px;
    color: white;
    border: 0vh;
    margin-top: 2vh;
    font-family: "Urbanist", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
  }
  body > #root > .home > main > .questions > .content > .context > .commonQuestion > div > button > a {
    color: white;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .carousel {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .textfreeMarket .infoFreeMarket > ul > li {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .infoLink {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .freeMarket > .content > .infoLink > div {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .system > .content > .infoSystem .textSystem > .box > .porcentagens {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .questions {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .home > main > .questions > .content > .context {
    flex: 0 0 auto !important;
  }
}
