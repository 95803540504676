/* Font Family */
/* Borda arredondada */
body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox {
  margin-left: 10vh;
  cursor: pointer;
  color: #003063;
}
body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox > hr {
  background-color: #A2AFCA;
  min-height: 1px !important;
  height: 1px !important;
  margin: 3vh 0;
}
body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox > .ReactCollapse--collapse > .ReactCollapse--content > .description {
  font-size: 16px;
}
body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox > .headLine > .name {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox > .headLine > .name {
    flex: 0 0 auto !important;
  }
}
body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox > .headLine > .name > .titulo {
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  color: #003063;
}
body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox > .headLine > .name > .icon > button {
  background-color: #F3F7FA;
  border: 0vh #F3F7FA;
}
body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox > .ReactCollapse--collapse > .ReactCollapse--content > .description {
  margin-top: 10px;
  line-height: 24px;
}
@media screen and (max-width: 479px) {
  body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox {
    margin-left: 0vh;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox {
    margin-left: 0vh;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .home > main > .questions > .content > .context > .question > .conditionsBox {
    margin-left: 0vh;
  }
}
