/* Font Family */
/* Borda arredondada */
body > #root nav.menu {
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid #F0F0F0;
  z-index: 999;
}
body > #root nav.menu > .container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 1200px;
  margin: auto;
  padding: 5px 0;
}
@media screen and (max-width: 767px) {
  body > #root nav.menu > .container {
    flex: 0 0 auto !important;
  }
}
body > #root nav.menu > .container > .logo {
  position: relative;
  z-index: 999;
}
body > #root nav.menu > .container > .logo > img {
  width: 200px;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root nav.menu > .container {
    padding: 6px 40px;
    width: 100%;
  }
  body > #root nav.menu > .container > .logo > img {
    width: 180px;
  }
}
