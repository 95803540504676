/* Font Family */
/* Borda arredondada */
body > #root > .container > nav.menuMobile {
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid #F0F0F0;
  z-index: 999;
}
body > #root > .container > nav.menuMobile > .container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: auto;
  padding: 9px 0 5px;
  width: auto;
}
@media screen and (max-width: 767px) {
  body > #root > .container > nav.menuMobile > .container {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > nav.menuMobile > .container > .logo {
  position: relative;
  z-index: 999;
}
body > #root > .container > nav.menuMobile > .container > .logo > img {
  width: 200px;
}
body > #root > .container > nav.menuMobile > .container > .menu_toggle {
  display: block;
  margin-top: -5px;
  margin-right: 20px;
  padding: 10px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-color: #ffcd00;
  z-index: 100;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .container > nav.menuMobile > .container > .menu_toggle > .one,
body > #root > .container > nav.menuMobile > .container > .menu_toggle .two,
body > #root > .container > nav.menuMobile > .container > .menu_toggle .three {
  background-color: #003063;
  height: 3px;
  width: 20px;
  margin: 4px auto;
  transition-duration: 0.3s;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > .container > main.mnOp {
  overflow-y: hidden;
}
body > #root > .container > main > .mnOverlay {
  position: fixed;
  top: 75px;
  bottom: 0;
  left: 100%;
  width: 100%;
  padding: 30px !important;
  transition-duration: 0.3s;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 999;
}
body > #root > .container > main > .mnOverlay.open {
  left: 0;
}
