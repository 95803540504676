/* Font Family */
/* Borda arredondada */
body > #root > .system > main {
  color: #003063;
}
body > #root > .system > main h1 {
  font-size: 50px;
  font-weight: 900;
}
body > #root > .system > main h2 {
  font-size: 34px;
  font-weight: 900;
}
body > #root > .system > main h3 {
  font-size: 30px;
  font-weight: 900;
}
body > #root > .system > main > div {
  padding: 112px 0;
}
body > #root > .system > main > .content,
body > #root > .system > main > div > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .content,
  body > #root > .system > main > div > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background: #FFF;
  background-image: linear-gradient(145deg, #ffffff 0%, rgba(150, 186, 220, 0.7) 100%);
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .header > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .header > .content > .texts {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 45%;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .texts {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .header > .content > .texts > h1 {
  margin-bottom: 15px;
}
body > #root > .system > main > .header > .content > .texts > p {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
}
body > #root > .system > main > .header > .content > .texts > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .texts > .btns {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .header > .content > .texts > .btns > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .texts > .btns > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .header > .content > .texts > .btns > a:not(:first-child) {
  margin-left: 10px;
}
body > #root > .system > main > .header > .content > .texts > .btns > a.analysis {
  color: #003063;
  background-color: #ffdc4d;
}
body > #root > .system > main > .header > .content > .texts > .btns > a.analysis:hover {
  background-color: #ffd21a;
}
body > #root > .system > main > .header > .content > .texts > .btns > a.buy {
  color: #FFF;
  background-color: #235AB1;
}
body > #root > .system > main > .header > .content > .texts > .btns > a.buy:hover {
  background-color: #1b4486;
}
body > #root > .system > main > .header > .content > .image {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 45%;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .image {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .header > .content > .image > img {
  width: 100%;
}
body > #root > .system > main > .system {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background-color: #F3F7FA;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .system {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .system > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .system > .content > .texts {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 42%;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .texts {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .system > .content > .texts > h2 {
  font-size: 38px;
}
body > #root > .system > main > .system > .content > .texts > h4 {
  font-size: 14px;
  color: #0061c9;
  text-transform: uppercase;
}
body > #root > .system > main > .system > .content > .texts > p {
  font-size: 15px;
  line-height: 25px;
}
body > #root > .system > main > .system > .content > .texts > ul {
  margin-top: 20px;
  padding: 0;
  list-style: none;
}
body > #root > .system > main > .system > .content > .texts > ul > li {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 10px 0;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .texts > ul > li {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .system > .content > .texts > ul > li > img {
  margin-right: 10px;
}
body > #root > .system > main > .system > .content > .texts > a.register {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #003063;
  background-color: #ffdc4d;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .texts > a.register {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .system > .content > .texts > a.register:hover {
  background-color: #ffd21a;
}
body > #root > .system > main > .system > .content > .image {
  margin-left: 10%;
  width: 48%;
}
body > #root > .system > main > .system > .content > .image > img {
  width: 100%;
}
body > #root > .system > main > .opps {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .opps {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .opps > h2 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 80px;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .opps > h2 {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .opps > .lines {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .opps > .lines {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .opps > .lines:not(:last-child) {
  margin-bottom: 80px;
}
body > #root > .system > main > .opps > .lines > .opp {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 25%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .opps > .lines > .opp {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .opps > .lines > .opp > .image {
  margin-bottom: 32px;
}
body > #root > .system > main > .opps > .lines > .opp > .image > img {
  width: 50%;
}
body > #root > .system > main > .opps > .lines > .opp > h4 {
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: 900;
}
body > #root > .system > main > .opps > .lines > .opp > p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}
body > #root > .system > main > .opps > .lines > .opp > a.link {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 20px;
  font-size: 13px;
  color: #0061c9;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .opps > .lines > .opp > a.link {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .opps > .lines > .opp > a.link:hover {
  color: #003063;
}
body > #root > .system > main > .opps > .lines > .opp > a.link > svg {
  margin-left: 8px;
  color: #0055b0;
}
body > #root > .system > main > .opps > .lines > .opp > a.btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #003063;
  background-color: #ffdc4d;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .opps > .lines > .opp > a.btn {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .opps > .lines > .opp > a.btn:hover {
  background-color: #ffd21a;
}
body > #root > .system > main > .benefits {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background-color: #F3F7FA;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .benefits {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .benefits > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .benefits > .content > h2 {
  margin: 0 0 24px;
  width: 50%;
  font-size: 42px;
}
body > #root > .system > main > .benefits > .content > h4 {
  width: 50%;
  font-size: 16px;
  font-weight: 400;
  color: #004996;
}
body > #root > .system > main > .benefits > .content > .testimonials {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .benefits > .content > .testimonials > .client {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  width: 30%;
  background-color: #FFF;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-box-shadow: 0 10px 20px -10px #bdd3e3;
  -webkit-box-shadow: 0 10px 20px -10px #bdd3e3;
  box-shadow: 0 10px 20px -10px #bdd3e3;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials > .client {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .benefits > .content > .testimonials > .client > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .photo > img {
  width: 100%;
}
body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 15px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .name,
body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .office {
  font-size: 13px;
}
body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .name:not(.office),
body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .office:not(.office) {
  font-weight: 600;
}
body > #root > .system > main > .benefits > .content > .testimonials > .client > p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}
body > #root > .system > main > .save {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background: url('../../assets/images/system/background.jpg') #003063;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .save {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .save > .content {
  color: #FFF;
}
body > #root > .system > main > .save > .content > h2 {
  margin: 0;
  font-size: 45px;
  width: 60%;
}
body > #root > .system > main > .save > .content > .description {
  margin: 24px 0 30px;
  width: 58%;
  font-size: 16px;
  line-height: 24px;
}
body > #root > .system > main > .save > .content > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .save > .content > .btns {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .save > .content > .btns > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main > .save > .content > .btns > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .system > main > .save > .content > .btns > a:not(:first-child) {
  margin-left: 10px;
}
body > #root > .system > main > .save > .content > .btns > a.buy {
  color: #003063;
  background-color: #ffdc4d;
}
body > #root > .system > main > .save > .content > .btns > a.buy:hover {
  background-color: #ffd21a;
}
body > #root > .system > main > .save > .content > .btns > a.works {
  color: #FFF;
  background-color: #163a71;
}
body > #root > .system > main > .save > .content > .btns > a.works:hover {
  background-color: #122f5c;
}
@media screen and (max-width: 767px) {
  body > #root > .system > main h1 {
    font-size: 42px;
  }
  body > #root > .system > main h2 {
    font-size: 40px;
  }
  body > #root > .system > main h3 {
    font-size: 34px;
  }
  body > #root > .system > main > div {
    padding: 0;
  }
  body > #root > .system > main > .content,
  body > #root > .system > main > div > .content {
    padding: 60px 20px;
    width: 100%;
  }
  body > #root > .system > main > .header > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    background: #FFF;
    background-image: linear-gradient(145deg, #ffffff 0%, rgba(150, 186, 220, 0.7) 100%);
  }
  body > #root > .system > main > .header > .content > .texts {
    width: 100%;
  }
  body > #root > .system > main > .header > .content > .texts > h1 {
    margin-bottom: 10px;
  }
  body > #root > .system > main > .header > .content > .texts > p {
    font-size: 18px;
    line-height: 28px;
  }
  body > #root > .system > main > .header > .content > .texts > .btns {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 25px;
  }
  body > #root > .system > main > .header > .content > .texts > .btns > a {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    padding: 15px 25px;
    font-size: 18px;
  }
  body > #root > .system > main > .header > .content > .texts > .btns > a:not(:first-child) {
    margin-top: 15px;
    margin-left: 0;
  }
  body > #root > .system > main > .header > .content > .image {
    margin: 80px -20px 0;
    width: auto;
  }
  body > #root > .system > main > .system > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .system > .content > .texts {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 100%;
  }
  body > #root > .system > main > .system > .content > .texts > h2 {
    font-size: 36px;
  }
  body > #root > .system > main > .system > .content > .texts > p {
    font-size: 17px;
    line-height: 26px;
  }
  body > #root > .system > main > .system > .content > .texts > ul > li {
    margin: 0 0 20px;
    font-size: 16px;
  }
  body > #root > .system > main > .system > .content > .texts > ul > li > img {
    margin-right: 10px;
  }
  body > #root > .system > main > .system > .content > .texts > a.register {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    margin-top: 20px;
    padding: 20px 25px;
    width: 100%;
    font-size: 17px;
    font-weight: 900;
  }
  body > #root > .system > main > .system > .content > .image {
    margin-top: 60px;
    margin-left: 0;
    width: 100%;
  }
  body > #root > .system > main > .system > .content > .image > img {
    width: 100%;
  }
  body > #root > .system > main > .opps {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .opps > h2 {
    font-size: 26px;
    text-align: center;
  }
  body > #root > .system > main > .opps > .lines {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .opps > .lines:not(:last-child) {
    margin-bottom: 80px;
  }
  body > #root > .system > main > .opps > .lines > .opp {
    width: 100%;
  }
  body > #root > .system > main > .opps > .lines > .opp:not(:last-child) {
    margin-bottom: 80px;
  }
  body > #root > .system > main > .opps > .lines > .opp > h4 {
    font-size: 20px;
  }
  body > #root > .system > main > .opps > .lines > .opp > p {
    font-size: 15px;
    line-height: 20px;
  }
  body > #root > .system > main > .opps > .lines > .opp > a.link {
    font-size: 16px;
  }
  body > #root > .system > main > .opps > .lines > .opp > a.btn {
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 900;
  }
  body > #root > .system > main > .benefits > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    padding-bottom: 80px;
  }
  body > #root > .system > main > .benefits > .content > h2 {
    margin: 0 0 24px;
    width: 100%;
    font-size: 36px;
  }
  body > #root > .system > main > .benefits > .content > h4 {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client {
    padding: 20px 15px 25px;
    width: 100%;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client:not(:first-child) {
    margin-top: 30px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-bottom: 25px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .photo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 60px;
    height: 60px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos {
    margin-left: 15px;
    width: 80%;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .name {
    font-size: 16px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .office {
    font-size: 13px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }
  body > #root > .system > main > .save > .content {
    padding: 100px 20px;
  }
  body > #root > .system > main > .save > .content > h2 {
    margin-bottom: 25px;
    font-size: 36px;
    width: 100%;
  }
  body > #root > .system > main > .save > .content > .description {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
  body > #root > .system > main > .save > .content > .btns {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 30px;
  }
  body > #root > .system > main > .save > .content > .btns > a {
    padding: 20px 30px;
    font-size: 16px;
    font-weight: 900;
  }
  body > #root > .system > main > .save > .content > .btns > a:not(:first-child) {
    margin-top: 15px;
    margin-left: 0;
  }
  body > #root > .system > main > .save > .content > .btns > a.buy {
    color: #003063;
    background-color: #ffdc4d;
  }
  body > #root > .system > main > .save > .content > .btns > a.buy:hover {
    background-color: #ffd21a;
  }
  body > #root > .system > main > .save > .content > .btns > a.works {
    color: #FFF;
    background-color: #163a71;
  }
  body > #root > .system > main > .save > .content > .btns > a.works:hover {
    background-color: #122f5c;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .texts > .btns {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .texts > .btns > a {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .texts {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .texts > a.register {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .opps {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .opps > .lines {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .photo {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .system > main > .save > .content > .btns {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .system > main h1 {
    font-size: 40px;
  }
  body > #root > .system > main h2 {
    font-size: 38px;
  }
  body > #root > .system > main h3 {
    font-size: 32px;
  }
  body > #root > .system > main > div {
    padding: 0;
  }
  body > #root > .system > main > .content,
  body > #root > .system > main > div > .content {
    padding: 60px 20px;
    width: 100%;
  }
  body > #root > .system > main > .header > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    background: #FFF;
    background-image: linear-gradient(145deg, #ffffff 0%, rgba(150, 186, 220, 0.7) 100%);
  }
  body > #root > .system > main > .header > .content > .texts {
    margin: auto;
    width: 70%;
    text-align: center;
  }
  body > #root > .system > main > .header > .content > .texts > h1 {
    margin-bottom: 10px;
  }
  body > #root > .system > main > .header > .content > .texts > p {
    font-size: 18px;
    line-height: 28px;
  }
  body > #root > .system > main > .header > .content > .texts > .btns {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 25px;
  }
  body > #root > .system > main > .header > .content > .texts > .btns > a {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    padding: 15px 25px;
    font-size: 18px;
  }
  body > #root > .system > main > .header > .content > .texts > .btns > a:not(:first-child) {
    margin-top: 15px;
    margin-left: 0;
  }
  body > #root > .system > main > .header > .content > .image {
    margin: 80px auto 0;
    width: 70%;
  }
  body > #root > .system > main > .system > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .system > .content > .texts {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 100%;
  }
  body > #root > .system > main > .system > .content > .texts > h2 {
    font-size: 32px;
  }
  body > #root > .system > main > .system > .content > .texts > p {
    font-size: 16px;
    line-height: 26px;
  }
  body > #root > .system > main > .system > .content > .texts > ul > li {
    margin: 0 0 20px;
    font-size: 15px;
  }
  body > #root > .system > main > .system > .content > .texts > ul > li > img {
    margin-right: 10px;
  }
  body > #root > .system > main > .system > .content > .texts > a.register {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    margin-top: 20px;
    padding: 20px 25px;
    font-size: 14px;
    font-weight: 900;
  }
  body > #root > .system > main > .system > .content > .image {
    margin: 0 auto 60px;
    width: 40%;
  }
  body > #root > .system > main > .system > .content > .image > img {
    width: 100%;
  }
  body > #root > .system > main > .opps {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .opps > h2 {
    font-size: 28px;
    text-align: center;
  }
  body > #root > .system > main > .opps > .lines {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .opps > .lines:not(:last-child) {
    margin-bottom: 80px;
  }
  body > #root > .system > main > .opps > .lines > .opp {
    margin: auto;
    width: 80%;
  }
  body > #root > .system > main > .opps > .lines > .opp:not(:last-child) {
    margin-bottom: 80px;
  }
  body > #root > .system > main > .opps > .lines > .opp > h4 {
    font-size: 22px;
  }
  body > #root > .system > main > .opps > .lines > .opp > p {
    font-size: 16px;
    line-height: 20px;
  }
  body > #root > .system > main > .opps > .lines > .opp > a.link {
    font-size: 16px;
  }
  body > #root > .system > main > .opps > .lines > .opp > a.btn {
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 900;
  }
  body > #root > .system > main > .benefits > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    padding-bottom: 80px;
  }
  body > #root > .system > main > .benefits > .content > h2 {
    margin: 0 auto 24px;
    width: 80%;
    font-size: 34px;
    text-align: center;
  }
  body > #root > .system > main > .benefits > .content > h4 {
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
  body > #root > .system > main > .benefits > .content > .testimonials {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client {
    padding: 20px 15px 25px;
    width: 100%;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client:not(:first-child) {
    margin-top: 30px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-bottom: 25px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .photo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 60px;
    height: 60px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos {
    margin-left: 20px;
    width: 90%;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .name {
    font-size: 18px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .infos > .office {
    font-size: 15px;
  }
  body > #root > .system > main > .benefits > .content > .testimonials > .client > p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
  }
  body > #root > .system > main > .save > .content {
    padding: 100px 20px;
  }
  body > #root > .system > main > .save > .content > h2 {
    margin-bottom: 25px;
    font-size: 38px;
    width: 100%;
  }
  body > #root > .system > main > .save > .content > .description {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
  body > #root > .system > main > .save > .content > .btns {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 30px;
  }
  body > #root > .system > main > .save > .content > .btns > a {
    padding: 20px 30px;
    font-size: 16px;
    font-weight: 900;
  }
  body > #root > .system > main > .save > .content > .btns > a:not(:first-child) {
    margin-top: 15px;
    margin-left: 0;
  }
  body > #root > .system > main > .save > .content > .btns > a.buy {
    color: #003063;
    background-color: #ffdc4d;
  }
  body > #root > .system > main > .save > .content > .btns > a.buy:hover {
    background-color: #ffd21a;
  }
  body > #root > .system > main > .save > .content > .btns > a.works {
    color: #FFF;
    background-color: #163a71;
  }
  body > #root > .system > main > .save > .content > .btns > a.works:hover {
    background-color: #122f5c;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .texts > .btns {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .header > .content > .texts > .btns > a {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .texts {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .texts > a.register {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .opps {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .opps > .lines {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .benefits > .content > .testimonials > .client > .header > .photo {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .system > main > .save > .content > .btns {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .system > main h1 {
    font-size: 46px;
  }
  body > #root > .system > main h2 {
    font-size: 32px;
  }
  body > #root > .system > main h3 {
    font-size: 28px;
  }
  body > #root > .system > main > div {
    padding: 0;
  }
  body > #root > .system > main > .content,
  body > #root > .system > main > div > .content {
    padding: 80px 40px;
    width: 100%;
  }
  body > #root > .system > main > .header > .content {
    padding-right: 20px;
  }
  body > #root > .system > main > .system > .content > .image {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) and screen and (max-width: 767px) {
  body > #root > .system > main > .system > .content > .image {
    flex: 0 0 auto !important;
  }
}
