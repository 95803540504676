/* Font Family */
/* Borda arredondada */
body > #root > .energy > main > section > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > section > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .call-to-economy {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/contact/14.png");
  background-position: bottom;
  background-size: cover;
  color: #FFF;
  text-align: center;
}
body > #root > .energy > main > .call-to-economy > .content > header > h1 {
  text-align: center;
}
body > #root > .energy > main > .call-to-economy > .content > header > .before-title {
  text-transform: uppercase;
  font-weight: 900;
  color: #ffcd00;
}
body > #root > .energy > main > .call-to-economy > .content > body {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  background-color: transparent;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .call-to-economy > .content > body {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .call-to-economy > .content > body > p {
  width: 50%;
}
body > #root > .energy > main > .call-to-economy > .content > body > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-top: 24px;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .call-to-economy > .content > body > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .call-to-economy > .content > body > .buttons > a {
  margin: 0 5px;
}
body > #root > .energy > main > .cases-economy {
  width: 100%;
  background-color: #F3F7FA;
  text-align: center;
}
body > #root > .energy > main > .cases-economy > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: auto;
  color: #003063;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .cases-economy > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .cases-economy > .content > header > h1 {
  text-align: center;
  margin-bottom: 14px;
}
body > #root > .energy > main > .cases-economy > .content > header > .before-title {
  text-transform: uppercase;
  font-weight: 900;
  color: #235AB1;
}
body > #root > .energy > main > .cases-economy > .content > body {
  background-color: transparent;
  width: 100%;
  text-align: center;
}
body > #root > .energy > main > .cases-economy > .content > body > p {
  width: 100%;
}
body > #root > .energy > main > .cases-economy > .content > body > .boxes {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}
body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 12px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div > img {
  max-width: 80%;
}
body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div h5 {
  margin: 16px 0;
}
body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > a > svg {
  margin: 0 5px;
}
body > #root > .energy > main > .contact {
  width: 100%;
  background-color: #FFF;
}
body > #root > .energy > main > .contact > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  color: #003063;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .contact > .content > .left {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 55%;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content > .left {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .contact > .content > .left > .before-title {
  color: #235AB1;
  text-transform: uppercase;
  font-weight: bold;
}
body > #root > .energy > main > .contact > .content > .left > h2 {
  margin-bottom: 24px;
}
body > #root > .energy > main > .contact > .content > .left > .btn-primary {
  margin-top: 24px;
}
body > #root > .energy > main > .contact > .content > .timeline {
  width: 45%;
}
body > #root > .energy > main > .contact > .content > .timeline > .item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content > .timeline > .item {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .contact > .content > .timeline > .item > .icons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content > .timeline > .item > .icons {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .contact > .content > .timeline > .item > .icons > img {
  color: #A2AFCA;
  width: 32px;
}
body > #root > .energy > main > .contact > .content > .timeline > .item > .icons > .vl {
  margin: 12px 0;
  border-left: 1px solid #A2AFCA;
  height: 100%;
}
body > #root > .energy > main > .contact > .content > .timeline > .item > .texts {
  padding-left: 16px ;
}
body > #root > .energy > main > .contact > .content > .timeline > .item > .texts > h6 {
  margin: 0;
}
body > #root > .energy > main > .contact > .content > .timeline > .item > .texts > p {
  margin-bottom: 48px;
}
body > #root > .energy > main > .carousel-content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .carousel-content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .carousel-content > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
  color: #003063;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .carousel-content > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .carousel-content > .content > h2 {
  width: 60%;
  margin: 0;
}
body > #root > .energy > main > .carousel-content > .content > p {
  width: 60%;
  margin: 12px 0;
}
body > #root > .energy > main > .consulting {
  width: 100%;
  background-color: #F3F7FA;
}
body > #root > .energy > main > .consulting > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  color: #003063;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .consulting > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .consulting > .content > img {
  width: 55%;
}
body > #root > .energy > main > .consulting > .content > div {
  width: 45%;
  padding: 0 26px 0 0;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .consulting > .content > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .consulting > .content > div > h2 {
  margin-bottom: 24px;
}
body > #root > .energy > main > .consulting > .content > div > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .consulting > .content > div > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .consulting > .content > div > .buttons > .btn-primary {
  margin-right: 15px;
}
body > #root > .energy > main > .consulting > .content > div > .buttons > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .consulting > .content > div > .buttons > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .consulting > .content > div > .buttons > a > svg {
  font-size: 24px;
}
body > #root > .energy > main > .subscribe {
  width: 100%;
  background-color: #FFF;
}
body > #root > .energy > main > .subscribe > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  color: #003063;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .subscribe > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .subscribe > .content > img {
  width: 45%;
}
body > #root > .energy > main > .subscribe > .content > div {
  width: 55%;
  padding: 0 0 0 26px;
}
body > #root > .energy > main > .subscribe > .content > div > p {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .subscribe > .content > div > p {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .subscribe > .content > div > p > svg {
  font-size: 24px;
  margin-right: 10px;
  color: #A2AFCA;
}
body > #root > .energy > main > .subscribe > .content > div > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .subscribe > .content > div > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .subscribe > .content > div > .buttons > .btn-primary {
  margin-right: 15px;
}
body > #root > .energy > main > .subscribe > .content > div > .buttons > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .subscribe > .content > div > .buttons > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .subscribe > .content > div > .buttons > a > svg {
  font-size: 24px;
}
body > #root > .energy > main > .discover {
  width: 100%;
  background-color: #003063;
}
body > #root > .energy > main > .discover > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .discover > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .discover > .content > img {
  width: 45%;
}
body > #root > .energy > main > .discover > .content > div {
  width: 55%;
  padding: 0 26px 0 0;
}
body > #root > .energy > main > .discover > .content > div > .percentage {
  color: #ffcd00;
  margin: 24px 0 0 0;
}
body > #root > .energy > main > .discover > .content > div > p {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .discover > .content > div > p {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .discover > .content > div > p > svg {
  font-size: 24px;
  margin-right: 10px;
  color: #A2AFCA;
}
body > #root > .energy > main > .discover > .content > div > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .discover > .content > div > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .discover > .content > div > .buttons > .btn-primary {
  margin-right: 15px;
}
body > #root > .energy > main > .discover > .content > div > .buttons > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .discover > .content > div > .buttons > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .discover > .content > div > .buttons > a > svg {
  font-size: 24px;
}
body > #root > .energy > main > .our-clients {
  background-color: #F3F7FA;
  width: 100%;
}
body > #root > .energy > main > .our-clients > .content {
  color: #003063;
}
body > #root > .energy > main > .our-clients > .content > h2 {
  width: 50%;
  margin: 0 0 24px 0;
}
body > #root > .energy > main > .our-clients > .content > .testimonials {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .our-clients > .content > .testimonials > .client {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  width: 30%;
  background-color: #FFF;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-box-shadow: 0 10px 20px -10px #bdd3e3;
  -webkit-box-shadow: 0 10px 20px -10px #bdd3e3;
  box-shadow: 0 10px 20px -10px #bdd3e3;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .photo > img {
  width: 100%;
}
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 15px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .infos > .name,
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .infos > .office {
  font-size: 13px;
}
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .infos > .name:not(.office),
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .infos > .office:not(.office) {
  font-weight: 600;
}
body > #root > .energy > main > .our-clients > .content > .testimonials > .client > p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (max-width: 1919px) {
  body > #root > .energy > main > .carousel-content > .content > h2 {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 1199px) {
  body > #root > .energy > main > .carousel-content > .content > h2 {
    width: 98%;
  }
  body > #root > .energy > main > .carousel-content > .content > p {
    width: 98%;
  }
}
@media screen and (max-width: 991px) {
  body > #root > .energy > main > .call-to-economy {
    text-align: left;
  }
  body > #root > .energy > main > .call-to-economy > .content > header > h1 {
    text-align: left;
  }
  body > #root > .energy > main > .call-to-economy > .content > body {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  body > #root > .energy > main > .call-to-economy > .content > body > p {
    width: 100%;
  }
  body > #root > .energy > main > .call-to-economy > .content > body > .buttons {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  body > #root > .energy > main > .call-to-economy > .content > body > .buttons > a {
    margin: 10px 5px;
  }
  body > #root > .energy > main > .cases-economy {
    width: 100%;
    text-align: left;
  }
  body > #root > .energy > main > .cases-economy > .content > header {
    width: 100%;
  }
  body > #root > .energy > main > .cases-economy > .content > header > h1 {
    text-align: left;
  }
  body > #root > .energy > main > .cases-economy > .content > body {
    text-align: left;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases {
    padding: 12px;
    margin: 24px 0;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div > img {
    max-width: 70%;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div h5 {
    margin: 16px 0;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div p {
    text-align: center;
    width: 70%;
  }
  body > #root > .energy > main > .contact > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  body > #root > .energy > main > .contact > .content > .left {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    width: 100%;
  }
  body > #root > .energy > main > .contact > .content > .left > .before-title {
    color: #235AB1;
    text-transform: uppercase;
    font-weight: bold;
  }
  body > #root > .energy > main > .contact > .content > .left > h2 {
    margin-bottom: 24px;
  }
  body > #root > .energy > main > .contact > .content > .left > .btn-primary {
    margin: 24px 0;
  }
  body > #root > .energy > main > .contact > .content > .timeline {
    width: 100%;
    margin-top: 24px;
  }
  body > #root > .energy > main > .contact > .content > .timeline > .item {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  body > #root > .energy > main > .contact > .content > .timeline > .item > .icons {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > .energy > main > .contact > .content > .timeline > .item > .icons > img {
    color: #A2AFCA;
    width: 32px;
  }
  body > #root > .energy > main > .contact > .content > .timeline > .item > .icons > .vl {
    margin: 12px 0;
    border-left: 1px solid #A2AFCA;
    height: 100%;
  }
  body > #root > .energy > main > .contact > .content > .timeline > .item > .texts {
    padding-left: 16px ;
  }
  body > #root > .energy > main > .contact > .content > .timeline > .item > .texts > h6 {
    margin: 0;
  }
  body > #root > .energy > main > .contact > .content > .timeline > .item > .texts > p {
    margin-bottom: 48px;
  }
  body > #root > .energy > main > .consulting > .content {
    flex-direction: column-reverse !important;
  }
  body > #root > .energy > main > .consulting > .content > img {
    margin-bottom: 40px;
    width: 70%;
  }
  body > #root > .energy > main > .consulting > .content > div {
    padding: 0;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    width: 100%;
  }
  body > #root > .energy > main > .subscribe {
    width: 100%;
  }
  body > #root > .energy > main > .subscribe > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    color: #003063;
  }
  body > #root > .energy > main > .subscribe > .content > img {
    width: 70%;
  }
  body > #root > .energy > main > .subscribe > .content > div {
    width: 100%;
    padding: 0;
  }
  body > #root > .energy > main > .discover > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    width: 1200px;
    padding: 64px 0;
    color: #FFF;
  }
  body > #root > .energy > main > .discover > .content > img {
    width: 75%;
    margin-top: 40px;
  }
  body > #root > .energy > main > .discover > .content > div {
    width: 100%;
    padding: 0;
  }
  body > #root > .energy > main > .our-clients > .content > h2 {
    width: 100%;
  }
  body > #root > .energy > main > .our-clients > .content > .testimonials {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 40px;
  }
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 100%;
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .call-to-economy > .content > body {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .call-to-economy > .content > body > .buttons {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .cases-economy > .content > body > .boxes {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content > .left {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content > .timeline > .item {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .contact > .content > .timeline > .item > .icons {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .consulting > .content > div {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .subscribe > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .discover > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) {
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases {
    padding: 12px;
    margin: 24px 0;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div > img {
    max-width: 100%;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div h5 {
    margin: 8px 0;
  }
  body > #root > .energy > main > .cases-economy > .content > body > .boxes > .box-cases > div p {
    width: 100%;
  }
  body > #root > .energy > main > .consulting > .content > img {
    width: 100%;
  }
  body > #root > .energy > main > .subscribe > .content > img {
    width: 100%;
  }
  body > #root > .energy > main > .discover > .content > img {
    width: 85%;
  }
  body > #root > .energy > main > .our-clients > .content > .testimonials {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 40px;
  }
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header > .infos {
    width: auto;
    max-width: 80%;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .energy > main > .our-clients > .content > .testimonials > .client > .header {
    flex: 0 0 auto !important;
  }
}
