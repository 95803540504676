/* Font Family */
/* Borda arredondada */
body > #root > .contact > main > .contact-us {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/contact/14.png");
  background-position: bottom;
  background-size: cover;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us {
    flex: 0 0 auto !important;
  }
}
body > #root > .contact > main > .contact-us > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  padding: 64px 32px;
  width: 1200px;
}
@media screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .contact > main > .contact-us > .content > header > h1 {
  margin: 0 0 16px 0;
}
body > #root > .contact > main > .contact-us > .content > header > p {
  margin: 0;
}
body > #root > .contact > main > .contact-us > .content > body {
  background-color: transparent;
  margin-top: 64px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
@media screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content > body {
    flex: 0 0 auto !important;
  }
}
body > #root > .contact > main > .contact-us > .content > body > .items {
  width: 40%;
  padding-right: 80px;
}
body > #root > .contact > main > .contact-us > .content > body > .items > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content > body > .items > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .contact > main > .contact-us > .content > body > .items > a > svg {
  font-size: 24px;
}
body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon {
  width: 100%;
  margin-bottom: 40px;
}
body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div svg {
  font-size: 32px;
  margin-right: 16px;
}
body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div h6 {
  margin: 0;
}
body > #root > .contact > main > .contact-us > .content > body > .map {
  width: 60%;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  height: 400px;
}
@media screen and (max-width: 1199px) {
  body > #root > .contact > main > .contact-us > .content > body > .items {
    padding-right: 32px;
  }
}
@media screen and (max-width: 991px) {
  body > #root > .contact > main > .contact-us {
    padding: 0 16px;
    height: auto;
  }
  body > #root > .contact > main > .contact-us > .content {
    margin-top: 24px;
    width: 100%;
  }
  body > #root > .contact > main > .contact-us > .content > body {
    background-color: transparent;
    margin-top: 64px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items {
    width: 100%;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items > a {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    color: #FFF;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items > a > svg {
    font-size: 24px;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon {
    width: 100%;
    margin-bottom: 40px;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div svg {
    font-size: 32px;
    margin-right: 16px;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div h6 {
    margin: 0;
  }
  body > #root > .contact > main > .contact-us > .content > body > .map {
    margin-top: 40px;
    width: 100%;
    min-height: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content > body {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content > body > .items > a {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content > body > .items > .box-icon > div {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) {
  body > #root > .contact > main > .contact-us > .content {
    padding: 32px 8px;
  }
  body > #root > .contact > main > .contact-us > .content > body > .items {
    padding-right: 0;
  }
}
