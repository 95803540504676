/* Font Family */
/* Borda arredondada */
body > #root > .container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 100vh;
  background-color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root > .container {
    flex: 0 0 auto !important;
  }
}
body > #root > .container h1 {
  font-size: 56px;
  font-weight: 900;
}
body > #root > .container h2 {
  font-size: 48px;
  font-weight: 900;
}
body > #root > .container h3 {
  font-size: 40px;
  font-weight: 900;
}
body > #root > .container h4 {
  font-size: 32px;
  font-weight: 900;
}
body > #root > .container h5 {
  font-size: 24px;
  font-weight: 900;
}
body > #root > .container h6 {
  font-size: 20px;
  font-weight: 900;
}
body > #root > .container p {
  font-size: 16px;
}
body > #root > .container > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
  margin-top: 71px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > div {
  padding: 112px 0;
}
body > #root > .container > main > .content,
body > #root > .container > main > div > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > .content,
  body > #root > .container > main > div > .content {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) {
  body > #root > .container h1 {
    font-size: 40px;
  }
  body > #root > .container h2 {
    font-size: 34px;
  }
  body > #root > .container h3 {
    font-size: 28px;
  }
  body > #root > .container h4 {
    font-size: 24px;
  }
  body > #root > .container h5 {
    font-size: 22px;
  }
  body > #root > .container h6 {
    font-size: 18px;
  }
  body > #root > .container > main {
    margin-top: 75px;
  }
  body > #root > .container > main > div {
    padding: 0 !important;
  }
  body > #root > .container > main > .content,
  body > #root > .container > main > div > .content {
    padding: 60px 20px !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .container h1 {
    font-size: 48px;
  }
  body > #root > .container h2 {
    font-size: 40px;
  }
  body > #root > .container h3 {
    font-size: 32px;
  }
  body > #root > .container h4 {
    font-size: 28px;
  }
  body > #root > .container h5 {
    font-size: 24px;
  }
  body > #root > .container h6 {
    font-size: 20px;
  }
  body > #root > .container > main {
    margin-top: 67px;
  }
  body > #root > .container > main > div {
    padding: 0 !important;
  }
  body > #root > .container > main > .content,
  body > #root > .container > main > div > .content {
    padding: 60px 20px !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .container > main {
    margin-top: 67px;
  }
  body > #root > .container > main > div {
    padding: 0 !important;
  }
  body > #root > .container > main > .content,
  body > #root > .container > main > div > .content {
    padding: 80px 40px !important;
    width: 100% !important;
  }
}
