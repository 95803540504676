/* Font Family */
/* Borda arredondada */
body > #root > .container > main > #banner {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: initial !important;
  -ms-flex-positive: initial !important;
  flex-grow: initial !important;
  position: relative;
  padding: 0;
  min-height: calc(100vh - 55px);
  color: #003063;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > #banner {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > #banner > .preloader {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-left: 30%;
  background-color: #FFF;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .preloader {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > #banner > .preloader > img {
  width: 100px;
}
body > #root > .container > main > #banner > .animation-container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -webkit-box-flex: initial !important;
  -ms-flex-positive: initial !important;
  flex-grow: initial !important;
  position: relative;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-width: auto;
  overflow: visible;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .animation-container {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > #banner > .animation-container > .palmsBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
}
body > #root > .container > main > #banner > .animation-container > .palmsBox > .palm {
  position: absolute;
  top: 7%;
  bottom: 0;
  right: 0;
  height: auto !important;
  z-index: 999;
}
body > #root > .container > main > #banner > .animation-container > .palmsBox > .palm_loop {
  position: absolute;
  top: 7%;
  bottom: 0;
  right: 0;
  height: auto !important;
  z-index: 998;
}
body > #root > .container > main > #banner > .animation-container > .water {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -webkit-box-flex: initial !important;
  -ms-flex-positive: initial !important;
  flex-grow: initial !important;
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  display: block;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: auto;
  min-width: auto;
  margin-top: 0;
  padding-left: 0;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  z-index: 61;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .animation-container > .water {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > #banner > .animation-container > .boat_left {
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0;
  display: block;
  width: auto;
  height: auto;
  z-index: 996;
}
body > #root > .container > main > #banner > .animation-container > .boat_right {
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0;
  display: block;
  width: auto;
  height: auto;
  z-index: 997;
}
body > #root > .container > main > #banner > .animation-container > .shops_back {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 72;
}
body > #root > .container > main > #banner > .animation-container > .shops_back .house_1 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 71;
}
body > #root > .container > main > #banner > .animation-container > .shops_back .house_2 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 70;
}
body > #root > .container > main > #banner > .animation-container > .shops_back .shops_2_light {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 69;
}
body > #root > .container > main > #banner > .animation-container > .shops_front {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 77;
}
body > #root > .container > main > #banner > .animation-container > .shops_front > .house_1 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 76;
}
body > #root > .container > main > #banner > .animation-container > .shops_front > .house_2 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 75;
}
body > #root > .container > main > #banner > .animation-container > .shops_front > .house_3 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 74;
}
body > #root > .container > main > #banner > .animation-container > .shops_front > .shops_lights {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 73;
}
body > #root > .container > main > #banner > .animation-container > .background {
  display: block;
  margin-top: -10px;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 68;
}
body > #root > .container > main > #banner > .animation-container > .background > .windows_1 {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: 1.5px;
  display: block;
  margin-top: -10px;
  width: auto;
  height: auto;
  min-width: auto;
  z-index: 67;
}
body > #root > .container > main > #banner > .animation-container > .background > .windows_2 {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: 1.5px;
  margin-top: -10px;
  width: auto;
  height: auto;
  max-width: none;
  min-width: auto;
  z-index: 66;
}
body > #root > .container > main > #banner > .animation-container .palm,
body > #root > .container > main > #banner > .animation-container .palms_loop,
body > #root > .container > main > #banner > .animation-container .water,
body > #root > .container > main > #banner > .animation-container .boat_left,
body > #root > .container > main > #banner > .animation-container .boat_right,
body > #root > .container > main > #banner > .animation-container .background,
body > #root > .container > main > #banner > .animation-container .house_1,
body > #root > .container > main > #banner > .animation-container .house_2,
body > #root > .container > main > #banner > .animation-container .house_3,
body > #root > .container > main > #banner > .animation-container .shops_1_lights,
body > #root > .container > main > #banner > .animation-container .shops_2_light,
body > #root > .container > main > #banner > .animation-container .windows_1,
body > #root > .container > main > #banner > .animation-container .windows_2 {
  width: 70vw !important;
  height: auto;
}
body > #root > .container > main > #banner > .infos {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
}
body > #root > .container > main > #banner > .infos > h1 {
  margin-bottom: 40px;
  width: 50%;
  font-size: 55px;
  font-weight: 900;
}
body > #root > .container > main > #banner > .infos > p {
  width: 50%;
  font-size: 17px;
  line-height: 26px;
}
body > #root > .container > main > #banner > .infos > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .infos > .btns {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > #banner > .infos > .btns > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 15px 25px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .infos > .btns > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > #banner > .infos > .btns > a:not(:first-child) {
  margin-left: 10px;
}
body > #root > .container > main > #banner > .infos > .btns > a.analysis {
  color: #003063;
  background-color: #ffdc4d;
}
body > #root > .container > main > #banner > .infos > .btns > a.analysis:hover {
  background-color: #ffd21a;
}
body > #root > .container > main > #banner > .infos > .btns > a.buy {
  color: #FFF;
  background-color: #235AB1;
}
body > #root > .container > main > #banner > .infos > .btns > a.buy:hover {
  background-color: #1b4486;
}
body > #root > .container > main > #banner > .mobile-container {
  opacity: 0.2;
  filter: alpha(opacity=20);
  /* For IE8 and earlier */
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .infos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    top: 20%;
    margin: 0;
    padding: 0 30px;
    width: 100%;
  }
  body > #root > .container > main > #banner > .infos > h1 {
    margin-bottom: 20px;
    width: 100%;
    font-size: 30px;
    text-align: center;
  }
  body > #root > .container > main > #banner > .infos > p {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  body > #root > .container > main > #banner > .infos > .btns {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 40px;
    width: 100%;
  }
  body > #root > .container > main > #banner > .infos > .btns > a {
    padding: 15px 20px;
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .infos {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .infos > .btns {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .container > main > #banner > .infos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    top: 100px;
    margin: 0;
    width: 100%;
  }
  body > #root > .container > main > #banner > .infos > h1 {
    width: 80%;
    font-size: 38px;
    text-align: center;
  }
  body > #root > .container > main > #banner > .infos > p {
    width: 70%;
    font-size: 16px;
    text-align: center;
  }
  body > #root > .container > main > #banner > .infos > .btns {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .infos {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > .container > main > #banner > .infos > .btns {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .container > main > #banner > .infos {
    top: 50px;
    width: 992px;
  }
  body > #root > .container > main > #banner > .infos > h1 {
    font-size: 38px;
  }
  body > #root > .container > main > #banner > .infos > p {
    font-size: 16px;
  }
}
