/* Font Family */
/* Borda arredondada */
body > #root > .container .links {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container .links {
    flex: 0 0 auto !important;
  }
}
body > #root > .container .links > a,
body > #root > .container .links > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  color: #003063;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container .links > a,
  body > #root > .container .links > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container .links > a:not(:first-child),
body > #root > .container .links > div:not(:first-child) {
  margin-left: 40px;
}
body > #root > .container .links > a:hover:not(.active),
body > #root > .container .links > div:hover:not(.active) {
  color: #666;
}
body > #root > .container .links > a.active,
body > #root > .container .links > div.active {
  font-weight: 600;
}
body > #root > .container .links > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container .links > .btns {
    flex: 0 0 auto !important;
  }
}
body > #root > .container .links > .btns > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container .links > .btns > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .container .links > .btns > a:not(:first-child) {
  margin-left: 10px;
}
body > #root > .container .links > .btns > a.login {
  color: #FFF;
  background-color: #235AB1;
}
body > #root > .container .links > .btns > a.login:hover {
  background-color: #1b4486;
}
body > #root > .container .links > .btns > a.register {
  color: #003063;
  background-color: #ffdc4d;
}
body > #root > .container .links > .btns > a.register:hover {
  background-color: #ffd21a;
}
body > #root > .container .links.mbe {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container .links.mbe {
    flex: 0 0 auto !important;
  }
}
body > #root > .container .links.mbe > a,
body > #root > .container .links.mbe > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  body > #root > .container .links.mbe > a,
  body > #root > .container .links.mbe > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container .links.mbe > a:not(:first-child),
body > #root > .container .links.mbe > div:not(:first-child) {
  margin-top: 40px;
  margin-left: 0;
}
body > #root > .container .links.mbe > a.active,
body > #root > .container .links.mbe > div.active {
  font-weight: 900;
}
body > #root > .container .links.mbe > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container .links.mbe > .btns {
    flex: 0 0 auto !important;
  }
}
body > #root > .container .links.mbe > .btns > a {
  width: 100%;
}
body > #root > .container .links.mbe > .btns > a:not(:first-child) {
  margin-left: 0;
  margin-top: 10px;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .container .links > a,
  body > #root > .container .links > div {
    font-size: 15px;
  }
  body > #root > .container .links > .btns > a {
    font-size: 13px;
  }
}
