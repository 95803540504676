/* Font Family */
/* Borda arredondada */
body > #root #carousel {
  width: inherit;
  position: relative;
  padding: 20px 0 40px;
}
body > #root #carousel #btn-right-carousel,
body > #root #carousel #btn-left-carousel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border: solid 1px #003063;
  width: 40px;
  height: 40px;
  font-size: 32px;
  color: #003063;
  background-color: transparent;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
@media screen and (max-width: 767px) {
  body > #root #carousel #btn-right-carousel,
  body > #root #carousel #btn-left-carousel {
    flex: 0 0 auto !important;
  }
}
body > #root #carousel #btn-right-carousel {
  position: absolute;
  bottom: 15px;
  right: 0;
}
body > #root #carousel #btn-left-carousel {
  position: absolute;
  bottom: 15px;
  right: 45px;
}
body > #root #carousel .container-dots {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-top: 15px;
}
@media screen and (max-width: 767px) {
  body > #root #carousel .container-dots {
    flex: 0 0 auto !important;
  }
}
body > #root #carousel .container-dots .dots-nav,
body > #root #carousel .container-dots .dots-nav-selected {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 8px !important;
  height: 8px !important;
  padding: 0;
  border: none;
  margin-left: 10px;
  background-color: #A2AFCA;
}
body > #root #carousel .container-dots .dots-nav-selected {
  background-color: #003063;
}
body > #root #carousel .item-carousel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 40vw;
  padding-right: 24px;
  margin-right: 40px !important;
}
@media screen and (max-width: 767px) {
  body > #root #carousel .item-carousel {
    flex: 0 0 auto !important;
  }
}
body > #root #carousel .item-carousel h4 {
  width: 100%;
  margin: 32px 0 16px 0;
}
body > #root #carousel .item-carousel p {
  margin: 0;
  width: 100%;
}
body > #root #carousel .item-carousel img {
  object-fit: contain;
  width: 100%;
}
@media screen and (max-width: 1919px) {
  body > #root #carousel .item-carousel {
    width: 47vw;
    padding-right: 32px;
    margin-right: 30px !important;
  }
}
@media screen and (max-width: 1439px) {
  body > #root #carousel .item-carousel {
    width: 53vw;
    margin-right: 20px !important;
    padding-right: 32px;
  }
}
@media screen and (max-width: 1199px) {
  body > #root #carousel .item-carousel {
    width: 58vw;
  }
}
@media screen and (max-width: 991px) {
  body > #root #carousel .item-carousel {
    width: 90vw;
    padding: 10px;
    margin: 0 !important;
  }
}
